import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { AuthState } from '@auth/states/auth.state';
import { BreakpointState, ScreenSize } from '@core/states/breakpoint/breakpoint.state';
import { HomeResponse, ResponseContentTypeForHomes } from '@features/home/types/home.type';
import { UtilService } from '@shared/services';
import { ContentBlock } from '@trendency/simple';
import { ContentSeoService } from '@trendency/simple/page';
import { ContentPageMeta } from '@trendency/simple/page/types/content-page.types';
import {
  BrandsLogosResponseType,
  HomeBrandResponseType,
  HomeFreshNewsResponseType,
  HomeHeroResponseType,
  HomeProgramCarouselResponseType,
  HomeVisitPlanningResponseType,
  MenusCardsResponseType,
  NewsletterResponseType,
  ResponseContentTypeForHomeBrands,
} from '@widgets/components';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class HomeService {
  readonly #http: HttpClient = inject(HttpClient);
  readonly #seoService: ContentSeoService = inject(ContentSeoService);
  readonly #utilService: UtilService = inject(UtilService);
  readonly #breakpointState = inject(BreakpointState);
  readonly #authState = inject(AuthState);

  readonly homepageBolcksOrderOnMobile: readonly string[] = [
    HomeHeroResponseType,
    HomeProgramCarouselResponseType,
    HomeVisitPlanningResponseType,
    MenusCardsResponseType,
    HomeBrandResponseType,
    HomeFreshNewsResponseType,
    NewsletterResponseType,
    BrandsLogosResponseType,
  ];

  readonly homepageBolcksOrderOnMobileWhenLoggedIn: readonly string[] = [
    HomeHeroResponseType,
    HomeProgramCarouselResponseType,
    HomeVisitPlanningResponseType,
    HomeBrandResponseType,
    HomeFreshNewsResponseType,
    NewsletterResponseType,
    BrandsLogosResponseType,
  ];

  readonly homepageBolcksOrderOnTablet: readonly string[] = [
    HomeHeroResponseType,
    HomeProgramCarouselResponseType,
    HomeBrandResponseType,
    HomeVisitPlanningResponseType,
    HomeFreshNewsResponseType,
    NewsletterResponseType,
    MenusCardsResponseType,
    BrandsLogosResponseType,
  ];

  readonly homepageBolcksOrderOnTabletWhenLoggedIn: readonly string[] = [
    HomeHeroResponseType,
    HomeProgramCarouselResponseType,
    HomeBrandResponseType,
    HomeFreshNewsResponseType,
    HomeVisitPlanningResponseType,
    NewsletterResponseType,
    BrandsLogosResponseType,
  ];

  readonly homepageBolcksOrderOnDesktop: readonly string[] = [
    HomeHeroResponseType,
    HomeProgramCarouselResponseType,
    HomeBrandResponseType,
    HomeVisitPlanningResponseType,
    HomeFreshNewsResponseType,
    NewsletterResponseType,
    MenusCardsResponseType,
    BrandsLogosResponseType,
  ];

  readonly homepageBolcksOrderOnDesktopWhenLoggedIn: readonly string[] = [
    HomeHeroResponseType,
    HomeProgramCarouselResponseType,
    HomeBrandResponseType,
    HomeFreshNewsResponseType,
    HomeVisitPlanningResponseType,
    NewsletterResponseType,
    BrandsLogosResponseType,
  ];

  readonly #filterBrands = (contents: readonly ResponseContentTypeForHomes[]): readonly ResponseContentTypeForHomeBrands[] =>
    contents.filter(
      (item: ResponseContentTypeForHomes) => item.template === HomeBrandResponseType
    ) as readonly ResponseContentTypeForHomeBrands[];

  readonly #uniqeBrands = (contents: readonly ResponseContentTypeForHomes[]): readonly ResponseContentTypeForHomes[] => {
    let hasBrandItem = false;
    return contents.filter((item: ResponseContentTypeForHomes) => {
      if (item.template !== HomeBrandResponseType) return true;
      else if (!hasBrandItem) {
        hasBrandItem = true;
        return true;
      }
      return false;
    });
  };

  readonly #getOrder = (screenSize: ScreenSize, isLoggedIn: boolean): readonly string[] => {
    return this[`homepageBolcksOrderOn${screenSize}${isLoggedIn ? 'WhenLoggedIn' : ''}`] as readonly string[];
  };

  fetchHome(): Observable<ContentBlock<unknown>[]> {
    return this.#http.get<HomeResponse>('homepage').pipe(
      tap((res) => this.#seoService.setMeta(res.data.meta as ContentPageMeta, res.data.meta?.title)),
      map((res: HomeResponse) => {
        const brands = this.#filterBrands(res.data.content);
        let homepages = this.#uniqeBrands(res.data.content).map((item: ResponseContentTypeForHomes) => {
          return {
            type: item.template,
            data: item.template === HomeBrandResponseType ? brands : item,
          };
        });

        if (this.#utilService.isBrowser()) {
          const blockOrder = this.#getOrder(this.#breakpointState.screenSize(), this.#authState.isLoggedIn());
          homepages = homepages.filter((block) => blockOrder.indexOf(block.type) >= 0);
          homepages.sort((a, b) => blockOrder.indexOf(a.type) - blockOrder.indexOf(b.type));
        }

        return homepages;
      })
    );
  }
}
