import { computed, inject, Injectable, Signal, untracked } from '@angular/core';
import { GlobalState } from '@core/states/global/global.state';
import { HomeService } from '@features/home/services/home.service';
import { tapResponse } from '@ngrx/operators';
import { patchState, signalState } from '@ngrx/signals';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { ContentBlock } from '@trendency/simple';
import { exhaustMap } from 'rxjs';

export type Brand = {
  readonly id: string;
  readonly title: string;
  readonly color: string;
  readonly imageUrl: string;
  readonly iconUrl: string;
};

export type HomeStateType = {
  readonly inHomePage: boolean;
  readonly homeSlideInTriggered: boolean;
  readonly homeSlideInHidden: boolean;
  readonly homeDelayedSlidesInHidden: boolean;
  readonly contentBlocks: readonly ContentBlock<unknown>[];
};

@Injectable({
  providedIn: 'root',
})
export class HomeState {
  readonly #homeService = inject(HomeService);
  readonly #globalState = inject(GlobalState);
  readonly #homeState = signalState<HomeStateType>({
    contentBlocks: [],
    inHomePage: false,
    homeSlideInTriggered: false,
    homeSlideInHidden: false,
    homeDelayedSlidesInHidden: false,
  });

  readonly contentBlocks: Signal<readonly ContentBlock<unknown>[]> = this.#homeState.contentBlocks;

  readonly slideIn = computed(() => {
    if (this.#homeState.inHomePage()) {
      if (this.#globalState.routeChangeStarted() && this.#globalState.routeChangeFinished()) {
        untracked(() => {
          patchState(this.#homeState, { homeSlideInTriggered: false });

          setTimeout(() => {
            patchState(this.#homeState, { homeSlideInHidden: true, homeDelayedSlidesInHidden: true });
          }, 500);
        });
      } else {
        untracked(() => {
          setTimeout(() => {
            patchState(this.#homeState, { homeSlideInHidden: false });
          }, 500);
        });

        if (this.#homeState.homeSlideInTriggered()) {
          untracked(() => {
            patchState(this.#homeState, { homeDelayedSlidesInHidden: false, homeSlideInTriggered: false });
          });
        }
      }
    }

    return {
      homeSlideInHidden: this.#homeState.homeSlideInHidden(),
      homeDelayedSlidesInHidden: this.#homeState.homeDelayedSlidesInHidden(),
    };
  });

  readonly #fetchContentBlocks = rxMethod<void>(
    exhaustMap(() =>
      this.#homeService.fetchHome().pipe(
        tapResponse({
          next: (contentBlocks) => patchState(this.#homeState, { contentBlocks }),
          error: console.error,
        })
      )
    )
  );

  loadContentBlocks(): void {
    this.#fetchContentBlocks();
  }

  get inHomePage(): Signal<boolean> {
    return this.#homeState.inHomePage;
  }

  set inHomePage(inHomePage: boolean) {
    patchState(this.#homeState, { inHomePage });
  }

  get homeSlideInTriggered(): Signal<boolean> {
    return this.#homeState.homeSlideInTriggered;
  }

  set homeSlideInTriggered(homeSlideInTriggered: boolean) {
    patchState(this.#homeState, { homeSlideInTriggered });
  }
}
